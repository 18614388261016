import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3395698585/src/website/components/FooterSC/FooterFilter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3395698585/src/website/components/FooterSC/LanguageSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3395698585/src/website/components/FooterSC/Newsletter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Link"] */ "/codebuild/output/src3395698585/src/website/components/Link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3395698585/src/website/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3395698585/src/website/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3395698585/src/website/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3395698585/src/website/public/icons/facebookIcon.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3395698585/src/website/public/icons/instagramIcon.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3395698585/src/website/public/icons/linkedinIcon.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3395698585/src/website/public/icons/twitterIcon.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3395698585/src/website/public/icons/verticalLogoWhiteIcon.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3395698585/src/website/public/images/brands/TGR.svg");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3395698585/src/website/public/images/brands/welkin-and-meraki.svg");
